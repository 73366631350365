<template>
  <div>
    <div class="apply-coupon">
      <v-text-field
        v-model="form.couponCode"
        :placeholder="couponCodePlaceholder"
        :color="colorBnn['color-bnn']"
        :readonly="validCoupon ? true : false"
        :clearable="coupon.isError && activeErrorKey === key"
        :error="coupon.isError && activeErrorKey === key"
        :hide-details="true"
        :class="{ 'hide-text': validCoupon }"
        type="text"
        ref="coupon_code"
        data-pptr="couponCode"
        outlined
        @keyup.enter="applyCoupon"
        @click:clear="clearCoupon"
      >
        <template v-slot:prepend-inner>
          <v-chip
            v-if="validCoupon"
            :class="['font-bold', { 'text-white': !isBnn }]"
            :color="colorBnn['color-bnn']"
          >
            {{ form.couponCode }}
            <span class="ml-2">
              <v-icon @click.stop="clearCoupon" small>
                mdi-close
              </v-icon>
            </span>
          </v-chip>
        </template>
      </v-text-field>

      <v-btn
        depressed
        class="text-white"
        :color="colorBnn['color-bnn']"
        :disabled="!form.couponCode"
        @click="applyCoupon"
      >
        ใช้ส่วนลด
      </v-btn>
    </div>
    <div
      v-if="msgError && activeErrorKey === key"
      class="message mt-2"
    >
      {{ msgError }}
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import { validationMixin } from 'vuelidate'
import cartSummary from '@/mixins/cartSummary'
import isBnn from '@/config/isBnn'
import colorBnn from '@/config/color'

export default {
  name: 'CheckoutApplyCoupon',

  mixins: [validationMixin, cartSummary],

  props: {
    key: {
      type: String,
      default: 'summary-main',
      required: true,
    },
    label: {
      type: String,
      default: 'กรอกคูปองส่วนลด',
    },
  },

  data() {
    return {
      // Import
      colorBnn,
      isBnn,

      form: {
        couponCode: '',
      },

      activeErrorKey: null,
    }
  },

  computed: {
    ...mapState({
      selectSKU: state => state.model.selectSKU,
      branchType: state => state.branchStore.branchType,
      coupon: state => state.cart.coupon,
      cartDelivery: state => state.cart.delivery,
      cartSummaryData: state => state.cart.cartSummaryData,
      paymentTypeData: state => state.paymentType.data,
      paymentId: state => state.paymentType.paymentId,
      selectBundleDelivery: state => state?.bundle.delivery,
      campaign: state => state.campaign.current,
      selects: state => state.bundle.selects,
    }),

    ...mapGetters({
      bundles: 'model/getBundles',
      additionalProduct: 'model/getAdditionalProduct',
      appleCare: 'model/getAppleCare',
      cartItems: 'cart/cartItems',
      productItem: 'cart/productItem',
      priceSummary: 'cart/priceSummary',
      isSoldOut: 'model/getSoldOut',
      bundleItems: 'cart/bundleItems',
      discountOnTop: 'cart/discountOnTop',
      deliveryItems: 'cart/deliveryItems',
      getBundleDelivery: 'model/getBundleDelivery',
      accessoryBundles: 'model/getAccessoryBundles',
      // getFreebieHandraiser
      handraiserFreebies: 'model/getHandraiserFreebies' ?? [],
    }),

    isSamsungHandraiser() {
      return (
        this.campaign?.data?.handraiser &&
        this.campaign?.data?.handraiser === 'samsung'
      )
    },

    forceCoupon() {
      return (
        this.campaign?.data?.force_coupon ?? ''
        // this.couponCodeParam ??
      )
    },

    validCoupon() {
      return !this.coupon.isError && this.coupon.code
    },

    couponCodePlaceholder() {
      return this.isSamsungHandraiser
        ? 'ใช้ Handraiser Code ที่นี่'
        : 'กรอกโค้ดส่วนลด'
    },

    msgError() {
      if (this.coupon.status === 422) {
        return !this.freebieListByCouponModal
          ? this.coupon.message
          : ''
      } else if (this.coupon.isError) {
        return 'โค้ดไม่ถูกต้อง'
      }
      return ''
    },
  },

  async mounted() {
    const paymentId =
      this.paymentId ||
      this.paymentTypeData?.filter(i =>
        this.isDeposit
          ? i.type === 'deposit'
          : this.$route.query.t
          ? i.type === 'pay-at-store'
          : i.type !== 'deposit',
      )?.[0]?.id

    this.$store.commit('paymentType/setPaymentId', paymentId)

    if (this.forceCoupon) {
      this.form.couponCode = this.forceCoupon
      const payload = {
        productId: this.selectSKU.id,
        couponCode: this.form.couponCode,
        bundleItems: this.bundleItems,
        paymentTypeId: paymentId,
      }

      await this.$store.dispatch('cart/checkCouponCode', payload)

      if (this.coupon.isError) {
        this.form.couponCode = ''
        this.$store.commit('cart/resetCouponCode')
      }
    }

    this.checkSummary()
  },

  methods: {
    async applyCoupon() {
      if (this.form.couponCode.trim()) {
        const payload = {
          productId: this.selectSKU.id,
          couponCode: this.form.couponCode,
          bundleItems: this.bundleItems,
          paymentTypeId: this.formCheckSummary.payment_type_id,
        }

        await this.$store.dispatch('cart/checkCouponCode', payload)

        if (this.coupon.isError) {
          this.activeErrorKey = this.key
        }

        if (this.coupon.autoAddToCart) {
          this.HandleFreebieWithCoupon('add')
        }

        this.checkSummary()

        // Notes: Hide modal freebie
        // if (this.coupon.data.length > 0) {
        //   this.$refs.modalSelectFreebie.open()
        // }
      }
    },

    async clearCoupon() {
      this.couponCode = ''
      this.form.couponCode = ''
      await this.HandleFreebieWithCoupon('remove')
      this.$store.commit('cart/resetCouponCode')
      this.checkSummary()
    },

    HandleFreebieWithCoupon(key) {
      if (this.coupon.autoAddToCart) {
        switch (key) {
          case 'remove':
            this.bundleItems.find(item => {
              if (item.sku === this.coupon.autoAddToCart) {
                this.$store.dispatch('bundle/remove', item)
              }
            })
            break

          default:
            this.handraiserFreebies?.find(item => {
              if (item.sku === this.coupon.autoAddToCart) {
                this.$store.dispatch('bundle/add', item)
              }
            })
            break
        }
      }
    },
  },

  watch: {
    'coupon.code': {
      handler() {
        if (this.coupon.code && !this.coupon.isError) {
          this.form.couponCode = this.coupon.code
        } else {
          this.form.couponCode = ''
        }
      },
    },
  },
}
</script>

<style lang="stylus" scoped>
.apply-coupon
  display flex
  flex-direction row
  flex-wrap nowrap
  align-items center
  gap 14px

form
  display flex
  flex-direction row
  align-items center

.message
  flex-basis 100%
  color red
  font-size 14px

.hide-text
  font-size: 0

::v-deep .v-text-field--enclosed .v-input__prepend-inner {
    margin: auto 0
}
</style>
